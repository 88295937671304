import React from 'react'

const How = () => {
  return (
    <div id="buy" className='bg-[#F2E3D0] py-6 md:py-10 xl:py-20'>
        <div className='flex justify-center'>
            <img className='max-w-[80%]' src={require('../../Assets/how.png')}/>
        </div>
        <div className='flex flex-wrap justify-center px-4 xl gap-6 mt-10 md:mt-20 xl:mt-40'>
            <img src={require('../../Assets/1.png')}/>
            <img src={require('../../Assets/2.png')}/>
            <img src={require('../../Assets/3.png')}/>
            <img src={require('../../Assets/4.png')}/>
        </div>
        <div className='flex justify-center mt-10 md:mt-40 xl:mt-80'>
            <img className='max-w-[80%]' src={require('../../Assets/roadmap_text.png')}/>
        </div>
    </div>
  )
}

export default How