import React from 'react'
import './head.css'
import ButtonText from './Button/ButtonText'
import { TG_LINK } from '../consts'
const Head = () => {
  return (
    <div id="home" className='bg-home flex justify-center items-center '>
        <img src={require('../Assets/home.gif')} className='w-[100%] sm:w-[85%] md:w-[55%] xl:w-[45%]'/>
        <div className='absolute bottom-10'>
            <ButtonText title="Buy $KUROMI" href={TG_LINK}/>
        </div>
    </div>
  )
}

export default Head