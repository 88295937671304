import React from 'react'

const Button = (props) => {
    const { title, icon, href } = props
    return (
        <a href={href}>
            <div className='relative'>
            <div className='absolute -top-[4px] -left-[4px] h-[50px] w-[50px] md:-top-[3px] md:-left-[3px] md:h-[60px] md:w-[60px] xl:-top-[2px] xl:-left-[2px] xl:h-[66px] xl:w-[66px] bg-[#F2E3D0] flex   border-2 border-black justify-center gap-2  items-center '>
                {title}
                {icon}
            </div>
            <div className='bg-black top-[6px] left-[6px] h-[54px] w-[54px] md:top-[6px] md:left-[6px] md:h-[60px] md:w-[60px] xl:top-[8px] xl:left-[8px] xl:h-[70px] xl:w-[70px]'>
                
            </div>
        </div>
        </a>
    )
}

export default Button