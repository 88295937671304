import React from 'react'

const ButtonText = (props) => {
  const { title, href, type = 'primary' } = props
  const getButton = (type) => {
    switch (type) {
      case 'primary':
        return <img src={require('../../Assets/button_kuro.png')} className='max-w-[80%]' />
      case 'secondary':
        return <img src={require('../../Assets/button_pink_kuro.png')} className='max-w-[80%]' />
      case 'tertiary':
        return <img src={require('../../Assets/button_green_kuro.png')} className='max-w-[80%]' />
    }
  }
  return (
    <div className='relative flex justify-center items-center'>
      <a href={href} className='flex justify-center items-center'>
        {getButton(type)}
        <div className='absolute inset-0 flex justify-center items-center '>
          <p className='pulang text-[20px] sm:text-[24px] md:text-[34px] font-[400]'>{title}</p>
        </div>
      </a>
    </div>
  )
}

export default ButtonText