import React from 'react'
import './about.css'
import AboutBox from '../AboutBox/AboutBox'
const About = () => {
  return (
    <div id='about' className='bg-[#FA88B3]  w-[100vw] p-2 sm:p-6 xl:p-20 h-full'>
        <div className='bg-about flex flex-col gap-10 xl:gap-4 xl:flex-row px-2 sm:px-6  xl:px-20 bg-[#F2E3D0] justify-between py-20'>
            <div className='flex justify-center items-center'>
                <img src={require('../../Assets/about.png')} />
            </div>
            <div className='flex justify-center items-center'>
                <AboutBox/>
            </div>
        </div>
    </div>
  )
}

export default About