import React from 'react'

const Footer = () => {
  return (
    <div className='bg-[#F2E3D0] py-4'>
        <div className='py-4 bg-black text-center text-white'>
        © 2024 KUROMI. All right reserved
        </div>
    </div>
  )
}

export default Footer