import React from 'react'
import { TG_LINK } from '../../consts'
import ButtonText from '../Button/ButtonText'

const Join = () => {
  return (
    <div className=' bg-[#F2E3D0] py-10 md:py-20 xl:py-40 flex flex-col justify-center px-6'>
        <img src={require('../../Assets/join_desktop.png')}/>
        <div className='pt-10'>
                <ButtonText type="tertiary" title="Buy $KUROMI" href={TG_LINK}/>
        </div>
    </div>
  )
}

export default Join