import React from 'react'

const Roadmap = () => {
  return (
    <div id='roadmap' className='bg-[#F2E3D0] bg-about min-h-[700px] w-[100vw] flex justify-center px-4'>
        <img src={require('../../Assets/roadmap.png')} className='py-[40px] md:py-[90px] xl:py-[120px]'/>
    </div>
  )
}

export default Roadmap