import React from 'react'
import './nav.css'
import Button from '../Button'
import '../../index.css'
import { TG_LINK, X_LINK } from '../../consts'
const Nav = () => {
    return (
        <div className='flex justify-center'>
            <div className='bg-nav flex justify-between absolute top-8 h-[100px] w-[80%] items-center px-4'>
                <img className='h-[50px]  md:h-[76px] flex' src={require('../../Assets/logo.png')} />
                <div className=' gap-[39px] xl:text-[28px] xxl:text-[32px] hidden xl:flex' >
                    <a href='#home'><p className='pulang cursor-pointer  text-[black] uppercase'>Home</p> </a>
                    <a href='#about'><p className='pulang cursor-pointer  text-[black] uppercase'>About</p> </a>
                    <a href='#kuronomics'><p className='pulang cursor-pointer  text-[black] uppercase'>Kuronomics</p> </a>
                    <a href='#buy'><p className='pulang cursor-pointer  text-[black] uppercase'>How to Buy</p> </a>
                    <a href='#roadmap'><p className='pulang cursor-pointer  text-[black] uppercase'>Roadmap</p> </a>
                </div>
                <div className='flex gap-4 '>
                    <Button href={TG_LINK} title="" icon={<Telegram />} />
                    <Button href={X_LINK} title="" icon={<Twitter />} />
                </div>
            </div>
        </div>
    )
}

export default Nav;

const Telegram = () => {
    return (
        <img src={require('../../Assets/tg.png')} className='h-[36px] w-[43px]' />
    )
}
const Twitter = () => {
    return (
        <img src={require('../../Assets/x.png')} className='h-[36px] w-[43px]' />
    )
}

