import React from 'react'
import '../About/about.css'
import Button from '../Button'
import ButtonText from '../Button/ButtonText'
import { TG_LINK } from '../../consts'
const AboutBox = () => {
    return (
        <div className='relative'>
            <img src={require('../../Assets/about_box_bg.png')} className=''/>
            <div className='absolute bottom-5 sm:bottom-10 md:bottom-20 flex justify-center w-full'>
                <ButtonText title="Buy $KUROMI" href={TG_LINK}/>
            </div>
        </div>
    )
}

export default AboutBox