import React from 'react'
import ButtonText from '../Button/ButtonText'
import { TG_LINK } from '../../consts'

const Tokenomics = () => {
  return (
    <div id="kuronomics" className='bg-[#F2E3D0] bg-about min-h-[700px] w-[100vw]'>
      <div className='flex justify-center relative -top-[30px] xl:-top-[100px]'>
        <img src={require('../../Assets/kuronomics.png')} className='max-w-[80%]' />
      </div>
      <div className='flex flex-col xl:flex-row gap-10 items-center justify-between px-[2%] md:px-[20%] relative -top-30 xl:-top-20'>
        <img className='w-[60%] sm:w-[70%] md:h-[500px] md:w-[100%]' src={require('../../Assets/tax.png')} />
        <img className='w-[60%] sm:w-[70%] md:h-[500px] md:w-[100%]' src={require('../../Assets/supply.png')} />
      </div>

      <div className='flex justify-center relative top-12 xl:-top-10'>
        <img src={require('../../Assets/kuro_pic.gif')} />
      </div>
      <div className='flex justify-center relative top-12 xl:-top-10 pb-20 md:pb-10'>
        <ButtonText type="secondary" color="#FA88B3" title="Buy $KUROMI" href={TG_LINK} />
      </div>
    </div>
  )
}

export default Tokenomics