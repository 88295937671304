import React from 'react'
import Marquee from "react-fast-marquee";

const Slider = () => {
    return (
        <div className='bg-[#FA88B3] w-[100vw] border-y-4 border-black'>
            <Marquee>
                <div className='h-[90px] md:h-[140px] flex justify-center items-center gap-8 md:gap-16'>
                    <div className='flex items-center gap-4'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/star.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/ticker.png')} />
                    </div>
                    <div className='flex items-center gap-4'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/star.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/ticker.png')} />
                    </div>
                    <div className='flex items-center gap-4'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/star.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/ticker.png')} />
                    </div>
                    <div className='flex items-center gap-4'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/star.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/ticker.png')} />
                    </div>
                    <div className='flex items-center gap-4'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/star.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/ticker.png')} />
                    </div>
                    <div className='flex items-center gap-4'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/star.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/ticker.png')} />
                    </div>
                    <div className='flex items-center gap-4'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/star.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/ticker.png')} />
                    </div>
                    <div className='flex items-center gap-4'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/star.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/ticker.png')} />
                    </div>

                </div>
            </Marquee>
        </div>
    )
}

export default Slider